module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pavement Maintenance Contractors Inc.","short_name":"PMCI","start_url":"/","background_color":"#0c4b33","theme_color":"#44b78b","display":"standalone","icon":"src/static/img/icon.svg","icons":[{"src":"src/static/img/icon-192x192.svg","sizes":"192x192","type":"image/png"},{"src":"src/static/img/icon-512x512.svg","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf3bd53afba5635f12026ab48f9b3129"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
